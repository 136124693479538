// src/components/Form.js
import React, {useState} from 'react';
import {Box, Button, FormControl, FormLabel, HStack, Input, VStack} from '@chakra-ui/react';

const Form = (props) => {
    const [qrText, setQrText] = useState(null);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        qr_text: ""
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };
    const submitHandler = (event) => {
        event.preventDefault();
        props.getQRCode(formData);
        console.log("Form Data: ", formData);
    }

    const downloadHandler = (event) => {
        event.preventDefault();
        props.downloadQRCode()
    }
    return (
        <Box maxW="md" mx="auto" p={4}>
            <VStack spacing={4}>
                <FormControl id="first_name" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input placeholder="First Name" onChange={handleChange} />
                </FormControl>
                <FormControl id="last_name" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input placeholder="Last Name" onChange={handleChange} />
                </FormControl>
                <FormControl id="email" isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" placeholder="Email"  onChange={handleChange}/>
                </FormControl>
                <FormControl id="qr_text" isRequired>
                    <FormLabel>QR Text</FormLabel>
                    <Input placeholder="Enter the text for QR"
                    onChange={handleChange}/>
                </FormControl>
                <HStack>
                    <Button id={"submit"} colorScheme="blue" type="submit" value="submit" onClick={submitHandler}>Submit</Button>
                    <Button id={"download"} colorScheme="blue" type="submit" value="download" onClick={downloadHandler}>Download QR</Button>
                </HStack>
            </VStack>
        </Box>
    );
};

export default Form;