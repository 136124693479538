import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://tctmfip8kx.us-east-1.awsapprunner.com/qr/",
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

export default axiosInstance;