// src/components/UnderConstruction.js
import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

function UnderConstruction() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
            <Heading as="h1" size="2xl" mb={4}>
                Under Construction
            </Heading>
            <Text fontSize="xl">
                This page is currently under construction. Please check back later.
            </Text>
        </Box>
    );
}

export default UnderConstruction;