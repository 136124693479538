import logo from './logo.svg';
import './App.css';
import Navbar from "./components/Navbar";
import QRGenerator from "./components/QRGenerator";
import {ChakraProvider} from "@chakra-ui/react";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Home from "./components/Home";
import UnderConstruction from "./components/UnderConstruction";

function App() {
    return (
        <ChakraProvider>
            <Router>
                <div className="App">
                    <Navbar/>
                    <header className="App-header">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/qrgenerator" element={<QRGenerator />} />
                            <Route path="/under-construction" element={<UnderConstruction />} />
                        </Routes>
                    </header>
                </div>
            </Router>
        </ChakraProvider>
    );
}

export default App;
