// src/components/Home.js
import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

function Home() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Heading as="h1" size="2xl">
                Welcome to Barely Complicated Applications
            </Heading>
        </Box>
    );
}

export default Home;